import React from 'react'
import PropTypes from 'prop-types'
import classNames from 'classnames'
import moment from 'moment-timezone'
import styles from './CompanyQuoteDisclosure.module.scss'
import {
  FaRegFilePdf,
  FaRegFileExcel,
  FaRegFileAlt,
  FaGlobe
} from 'react-icons/fa'
import { dynamicSort } from '../../utils/dynamicSort'
import { format } from '../../utils/locale'
import { logPageview } from '../../analytics'

import { Flex, Box } from 'grid-styled'
import {
  getSECFilings, getSedarFilings
} from '../../api/otc/company/filings'
import {
  getExternalInsiderDisclosure,
  getOTCInsiderDisclosure,
  getOTCInsiderDisclosureSummary
} from '../../api/otc/company/disclosure'
import {
  getQuarterlyCallReports,
  getFRY6Reports,
  getFRY9Reports,
  getFDICReports
} from '../../api/otc/company/qaravan'

import {
  getFinancialReports
} from '../../api/otc/company/financials'

import { template, fetch, expand, sort } from '../../api/helper'
import api from '../../api/api'
import getConfig from '../../config'
import Outline from '../Outline'
import Table from '../Table'
import Loading from '../Loading'
import More from '../More'
import TableFooter from '../TableFooter'
import DisplayResults from '../DisplayResults'
import TableTabs from '../TableTabs'
import Locale from '../Locale'
import TableItemDownloads from '../TableItems/TableItemDownloads'

const { REACT_APP_QARAVAN_CALL_REPORT_EXPORT_URI, REACT_APP_QARAVAN_FRY9_REPORT_EXPORT_URI } = getConfig()
const hostname = window.location.origin

const newsAndDisclosureColumns = [{
  name: 'releaseDate',
  header: 'Publish Date',
  type: 'date'
}, {
  name: 'name',
  header: 'Title',
  target: 'blank',
  linkTo: (item) => api.link({
    otcAPI: true,
    url: `/backend-content/company/financial-report/${item.id}/content`
  })
}, {
  name: 'periodDate',
  header: 'Period End Date',
  type: 'date',
  sortingEnabled: true

}, {
  name: 'statusId',
  header: 'Status',
  hideOnMobile: true
}]

const fdicReportsColumns = (company) => {
  const reportUrl = (item) => {
    return `${hostname}/otc-content/stock/${company}/efr/${item.bankId}/${item.entryId}/${item.attachmentId}`
  }
  return [
    {
      name: 'formType',
      header: 'Form Type',
      linkTo: (item) => (item.attachmentId) ? reportUrl(item) : false
    },
    {

      name: 'receivedDate',
      header: 'Received'
    },
    {
      name: 'periodEndDate',
      header: 'Period End Date'
    },
    {
      name: 'Report',
      component: TableItemDownloads,
      componentOptions: {
        buttons: [
          {
            name: 'PDF',
            icon: FaRegFilePdf,
            show: (item) => !!(item.attachmentId),
            linkTo: reportUrl
          }
        ]
      }
    }
  ]
}

const bankRegulatoryReportsColumns = (company) => {
  const reportUrl = (item) => {
    if (callReportFormTypeMap.get(item.formTypeId)) {
      return `${hostname}/otc-content/stock/${company}/call-report/${item.bankId}/${item.periodEndDate.replace(/\//g, '%2F')}`
    } else if (fry9ReportFormTypeMap.get(item.formTypeId)) {
      return `${hostname}/otc-content/stock/${company}/fry9/${item.formTypeId}/${item.bankId}/${item.periodEndDate.replace(/\//g, '%2F')}`
    } else if (item.formTypeId && item.formTypeId === fry6FormTypeId) {
      const fry6Url = `${hostname}/otc-content/stock/${company}/fry6/${item.bankId}/${item.periodEndDate.replace(/\//g, '%2F')}`
      const version = item.version
      return `${fry6Url}${version ? `/${version}` : ''}`
    } else { return '' }
  }
  return [{
    name: 'periodEndDate',
    header: 'Period End Date',
    linkTo: reportUrl,
    sortingEnabled: true
  }, {
    name: 'formType',
    header: 'Form Type',
    linkTo: reportUrl
  }, {
    header: 'Report',
    component: TableItemDownloads,
    componentOptions: {
      buttons: [{
        name: 'PDF',
        icon: FaRegFilePdf,
        linkTo: reportUrl
      },
      {
        name: 'XLS',
        icon: FaRegFileExcel,
        show: (item) => !!(callReportFormTypeMap.get(item.formTypeId) || fry9ReportFormTypeMap.get(item.formTypeId)),
        linkTo: (item) => {
          let exportUrl
          const isCallReport = callReportFormTypeMap.get(item.formTypeId)
          const isFry9 = fry9ReportFormTypeMap.get(item.formTypeId)
          if (isCallReport) exportUrl = REACT_APP_QARAVAN_CALL_REPORT_EXPORT_URI
          if (isFry9) exportUrl = REACT_APP_QARAVAN_FRY9_REPORT_EXPORT_URI
          return `${exportUrl}?rssdId=${item.bankId}&period=${item.periodEndDate}${isFry9 ? `&type=${item.formTypeId}` : ''}`
        }
      }
      ]
    }
  }]
}

const sedarFilingsColumns = [{
  name: 'releaseDate',
  header: 'Publish Date',
  noWrap: true,
  type: 'date'
}, {
  name: 'title',
  header: 'Document',
  linkTo: (item) => {
    if (item.storageId) {
      return `${hostname}/otc-content/filing-file/${item.storageId}/contents`
    } else {
      return null
    }
  }
}, {
  name: 'periodDate',
  header: 'Period End Date',
  noWrap: true,
  type: 'date'
}];


const secFilingsColumns = [{
  name: 'formType',
  header: 'Form Type',
  target: 'blank',
  linkTo: (item) => (item.paperOnly) ? false : `${hostname}/otc-content/filing/html?id=${item.id}&guid=${api.getGUID(item.guid)}`,
  sortingEnabled: true
}, {
  name: 'receivedDate',
  header: 'Received',
  type: 'date',
  sortingEnabled: true
}, {
  name: 'periodEndDate',
  header: 'Period End Date',
  type: 'date',
  sortingEnabled: true
}, {
  name: 'report',
  header: 'Report',
  hideOnMobile: true,
  component: TableItemDownloads,
  componentOptions: {
    buttons: [{
      name: 'PDF',
      icon: FaRegFilePdf,
      linkTo: (item) => item.hasPdf ? `${hostname}/otc-content/filing/pdf?id=${item.id}&guid=${api.getGUID(item.guid)}` : `${hostname}/otc-content/filing/conv_pdf?id=${item.id}&guid=${api.getGUID(item.guid)}`
    }, {
      name: 'RTF',
      icon: FaRegFileAlt,
      linkTo: (item) => `${hostname}/otc-content/filing/rtf?id=${item.id}&guid=${api.getGUID(item.guid)}`
    }, {
      name: 'HTML',
      icon: FaGlobe,
      linkTo: (item) => `${hostname}/otc-content/filing/html?id=${item.id}&guid=${api.getGUID(item.guid)}`
    }, {
      name: 'XLS',
      icon: FaRegFileExcel,
      show: (item) => item.hasExcel,
      linkTo: (item) => `${hostname}/otc-content/filing/xls?id=${item.id}&guid=${api.getGUID(item.guid)}`
    }]
  }
}]

const secTransactionsColumns = [{
  name: 'transDate',
  header: 'Trans Date',
  type: 'date'
}, {
  name: 'filerName',
  header: 'Filer'
}, {
  name: 'ownershipTypeName',
  header: 'Ownership',
  hideOnMobile: true
}, {
  name: 'typeName',
  header: 'Type',
  hideOnMobile: true
}, {
  name: 'price',
  header: 'Price',
  type: 'price'
}, {
  name: 'sharesTraded',
  header: 'Shares Traded',
  type: 'int',
  hideOnMobile: true
}, {
  name: 'sharesHeld',
  header: 'Shares Held',
  type: 'int',
  hideOnMobile: true
}]

const OTCInsiderColumns = [{
  name: 'releaseDate',
  header: 'Date',
  type: 'date'
}, {
  name: 'title',
  header: 'Title',
  target: 'blank',
  linkTo: (item) => api.link({
    otcAPI: true,
    url: `/backend-content/company/financial-report/${item.id}/content`
  })
}, {
  name: 'typeName',
  header: 'Type'
}]

const fry6FormTypeId = 'Fry6Id'
const fry6FormType = 'FR-Y6 Annual Report of Holding Companies'

const callReportFormTypeMap = new Map([
  [31, 'FFIEC 031 Quarterly Call Report'],
  [41, 'FFIEC 041 Quarterly Call Report'],
  [51, 'FFIEC 051 Quarterly Call Report']])

const fry9FormTypeC = 'C'
const fry9FormTypeSp = 'SP'
const fry9FormTypeLp = 'LP'
const fry9ReportFormTypeMap = new Map([
  [fry9FormTypeC, 'FR-Y9C Consolidated Bank Holding Company Financials'],
  [fry9FormTypeSp, 'FR-Y9SP Parent Only Financials, Small Bank Holding Company'],
  [fry9FormTypeLp, 'FR-Y9LP Parent Only Financials, Large Bank Holding Company']])

const SedarFooter = () => <div className={styles.footerNote}>1 SEDAR and SEDAR+ are trademarks owned by the Alberta Securities Commission.</div>

class CompanyQuoteDisclosure extends React.PureComponent {
  constructor (props, context) {
    super(props, context)
    this.state = {
      secFilings: template('records', 'receivedDate', 'desc'),
      sedarFilings: template('records', 'periodEndDate', 'desc'),
      financialReports: template('records', 'releaseDate', 'desc'),
      externalInsiderDisclosure: template('records', 'transDate', 'desc'),
      OTCInsiderDisclosure: template('records', 'transDate', 'DESC'),
      OTCInsiderDisclosureSummary: {},
      quarterlyCallReports: template('records', 'periodEndDate', 'DESC'),
      fry9Reports: template('records', 'periodEndDate', 'DESC'),
      fry6Reports: template('records', 'periodEndDate', 'DESC'),
      fdicReports: template('records', 'periodEndDate', 'DESC'),
      bankRegulatoryReports: {
        loaded: false,
        reloading: false,
        data: [],
        error: null,
        currentPage: 1,
        pageSize: 10,
        displayReports: []
      },
      selectedTab: 'active'
    }

    this.sortBankRegulatoryReports = this.sortBankRegulatoryReports.bind(this)
  }

  componentDidMount () {
    this.loadSecFilings()
    this.loadSedarFilings()
    this.loadExternalInsiderDisclosure()
    this.loadOTCInsiderDisclosure()
    this.loadOTCInsiderDisclosureSummary()
    this.loadQuarterlyCallReports()
    this.loadFinancialReports()
    this.loadFRY9Reports()
    this.loadFRY6Reports()
    this.loadFDICReports()

    const title = `OTC Markets | ${this.props.company} | Disclosure`
    logPageview(title)
  }

  loadSedarFilings = () => {
    const isSedarDataEligibleSecurity = this.checkSedarEligibleSecurity() && this.props.info.isDnsClient

    if (isSedarDataEligibleSecurity) {
      fetch(this, getSedarFilings, 'sedarFilings', {
        companyId: this.props.info.id,
        status: 'ACTIVE',
        reportExternalSource: 'SEDAR',
        sortOn: 'periodDate',
        page: 1,
        pageSize: 10
      })
    }
  }

  loadSecFilings = () => {
    fetch(this, getSECFilings, 'secFilings', {
      symbol: this.props.company,
      page: 1,
      pageSize: 10
    })
  }

  loadFinancialReports = () => {
    const { company } = this.props
    let statusId = null

    if (this.state.selectedTab === 'active') {
      statusId = 'A'
    } else if (this.state.selectedTab === 'inactive') {
      statusId = 'I'
    }

    // get OTC disclosure reports
    fetch(this, getFinancialReports, 'financialReports', {
      symbol: company,
      page: 1,
      pageSize: 10,
      statusId,
      sortOn: 'releaseDate',
      sortDir: 'DESC'
    })
  }

  loadExternalInsiderDisclosure = () => {
    fetch(this, getExternalInsiderDisclosure, 'externalInsiderDisclosure', {
      symbol: this.props.company,
      sortOn: 'transDate',
      sortDir: 'DESC',
      page: 1,
      pageSize: 10
    }, data => {
        data.records = data.records.map(record => {
        let stockTransaction

        if ([1, 3, 5, 7, 8, 11, 12, 13, 15, 16].includes(record.typeId)) {
          stockTransaction = 'green'
        } else if ([2, 4, 6, 9, 10, 14, 17].includes(record.typeId)) {
          stockTransaction = 'red'
        } else if (record.formTypeId === 5) {
          stockTransaction = 'green'
        }

        return {
          transDate: record.transDate,
          filerName: <span>{record.filerName}<br />{record.relationshipName}</span>,
          ownershipTypeName: record.ownershipTypeName && (record.ownershipTypeName.charAt(0).toUpperCase() + record.ownershipTypeName.slice(1)),
          typeName: record.typeName,
          price: record.priceTo !== undefined
            ? `${record.priceFrom} to ${record.priceTo}`
            : `${record.priceFrom}`,
          sharesTraded: record.numberOfShares,
          sharesHeld: record.amountOwned,
          ...(stockTransaction && { stockTransaction })
        }
      })
      return data
    })
  }

  loadOTCInsiderDisclosure = () => {
    fetch(this, getOTCInsiderDisclosure, 'OTCInsiderDisclosure', {
      symbol: this.props.company,
      sortOn: 'transDate',
      sortDir: 'DESC',
      page: 1,
      pageSize: 10
    })
  }

  loadQuarterlyCallReports = () => {
    if (this.props.info.bankId) {
      fetch(this, getQuarterlyCallReports, 'quarterlyCallReports', {
        bankId: this.props.info.bankId
      }, response => {
        let data = response && response.data
        if (!data) data = { records: [] }
        if (!data.records) data.records = []
        data.records = data.map(record => {
          return {
            bankId: this.props.info.bankId,
            periodEndDate: format(record.period, 'N/A', 'date'),
            displayReportDate: format(record.period, 'N/A', 'date'),
            formTypeId: record.formType,
            formType: callReportFormTypeMap.get(record.formType) || ''
          }
        })
        return data
      }).then(() => {
        this.setBankRegulatoryReports()
      }).catch(() => {
        this.handleNoCallReports()
      })
    } else {
      this.handleNoCallReports()
    }
  }

  handleNoCallReports = () => {
    this.setState({
      quarterlyCallReports: {
        ...this.state.quarterlyCallReports,
        loaded: true,
        complete: true
      }
    }, () => {
      this.setBankRegulatoryReports()
    })
  }

  loadFRY6Reports = () => {
    if (this.props.info.traderRssdId) {
      const traderRssdId = this.props.info.traderRssdId
      fetch(this, getFRY6Reports, 'fry6Reports', {
        traderRssdId
      }, response => {
        let data = response && response.data
        if (!data) data = { records: [] }
        if (!data.records) data.records = []
        // api just returns the date of the reports in YYYY-MM-DD format
        data.records = data.map(record => {
          const periodEndDateStr = format(record.p, 'N/A', 'date')
          const version = record.v
          let displayReportDate = ''
          const dateMoment = moment(record, 'YYYY-MM-DD')
          if (dateMoment && dateMoment.month() === 11 && dateMoment.date() === 30) {
            displayReportDate = dateMoment.year()
          } else {
            displayReportDate = periodEndDateStr
          }
          return {
            bankId: traderRssdId,
            periodEndDate: periodEndDateStr,
            displayReportDate,
            formTypeId: fry6FormTypeId,
            formType: fry6FormType,
            version
          }
        })
        return data
      }).then(() => {
        this.setBankRegulatoryReports()
      }).catch(() => {
        this.handleNoFry6Reports()
      })
    } else {
      this.handleNoFry6Reports()
    }
  }

  handleNoFry6Reports = () => {
    this.setState({
      fry6Reports: {
        ...this.state.fry6Reports,
        loaded: true,
        complete: true
      }
    }, () => {
      this.setBankRegulatoryReports()
    })
  }

  loadFRY9Reports = () => {
    if (this.props.info.traderRssdId) {
      const traderRssdId = this.props.info.traderRssdId
      fetch(this, getFRY9Reports, 'fry9Reports', {
        traderRssdId
      }, response => {
        let data = response && response.data
        if (!data) data = { data: {} }
        if (!data.c) data.c = []
        if (!data.lp) data.lp = []
        if (!data.sp) data.sp = []
        data.c = data.c.map(record => {
          return {
            bankId: traderRssdId,
            periodEndDate: format(record, 'N/A', 'date'),
            displayReportDate: format(record, 'N/A', 'date'),
            formTypeId: fry9FormTypeC,
            formType: fry9ReportFormTypeMap.get(fry9FormTypeC) || ''
          }
        })

        data.lp = data.lp.map(record => {
          return {
            bankId: traderRssdId,
            periodEndDate: format(record, 'N/A', 'date'),
            displayReportDate: format(record, 'N/A', 'date'),
            formTypeId: fry9FormTypeLp,
            formType: fry9ReportFormTypeMap.get(fry9FormTypeLp) || ''
          }
        })

        data.sp = data.sp.map(record => {
          return {
            bankId: traderRssdId,
            periodEndDate: format(record, 'N/A', 'date'),
            displayReportDate: format(record, 'N/A', 'date'),
            formTypeId: fry9FormTypeSp,
            formType: fry9ReportFormTypeMap.get(fry9FormTypeSp) || ''
          }
        })

        data.records = [].concat(data.c, data.lp, data.sp)
        return data
      }).then(() => {
        this.setBankRegulatoryReports()
      }).catch(() => {
        this.handleNoFry9Reports()
      })
    } else {
      this.handleNoFry9Reports()
    }
  }

  handleNoFry9Reports = () => {
    this.setState({
      fry9Reports: {
        ...this.state.fry9Reports,
        loaded: true,
        complete: true
      }
    }, () => {
      this.setBankRegulatoryReports()
    })
  }

  loadFDICReports = () => {
    const traderRssdId = this.props.info.traderRssdId
    if (traderRssdId) {
      fetch(this, getFDICReports, 'fdicReports', {
        traderRssdId: traderRssdId,
        page: 1,
        pageSize: 10
      }, response => {
        let data = response && response.data
        if (!data) data = { items: [] }
        if (!data.items) data.items = []
        data.records = data.items.map(record => {
          const periodEndDateStr = format(record.eventCauseDt, 'N/A', 'date')
          let receivedDateStr
          let attachmentId
          if (!record.attachments) record.attachments = []
          let pdfAttachment = record.attachments.find(attachment => attachment.fileType === 'pdf')
          pdfAttachment = pdfAttachment || record.attachments[0] // if no pdf, use first attachment as default to show as PDF

          if (pdfAttachment) {
            receivedDateStr = format(pdfAttachment.createdDtTm, 'N/A', 'date')
            attachmentId = pdfAttachment.id
          }

          return {
            entryId: record.id,
            bankId: traderRssdId,
            periodEndDate: periodEndDateStr,
            formType: record.formType,
            // from PDF attachments
            attachmentId: attachmentId,
            receivedDate: receivedDateStr
          }
        })
        data.notEmpty = data.items.length !== 0
        data.totalRecords = data.count
        return data
      })
    }
  }

  setBankRegulatoryReports = () => {
    const callReports = this.state.quarterlyCallReports
    const fry9Reports = this.state.fry9Reports
    const fry6Reports = this.state.fry6Reports
    if (callReports.loaded && fry9Reports.loaded && fry6Reports.loaded) {
      let error = null
      if (callReports.error && fry9Reports.error && fry6Reports.error) {
        error = 'Something went wrong, please try again.'
      }

      const mergeReports = [].concat(callReports.data.records, fry9Reports.data.records, fry6Reports.data.records)
        .sort(dynamicSort('periodEndDate', 'date', 'DESC'))
      const page = this.state.bankRegulatoryReports.currentPage || 1
      const pageSize = this.state.bankRegulatoryReports.pageSize || 10

      const displayReports = mergeReports.slice(0, pageSize)
      this.setState({
        bankRegulatoryReports: {
          ...this.state.bankRegulatoryReports,
          loaded: true,
          displayReports,
          sortDir: 'DESC',
          currentPage: page,
          pageSize,
          error,
          data: mergeReports
        }
      })
    }
  }

  showMoreBankRegulatoryReports = () => {
    const page = this.state.bankRegulatoryReports.currentPage + 1
    const pageSize = this.state.bankRegulatoryReports.pageSize
    const pageStart = (page - 1) * pageSize
    const pageEnd = ((page - 1) * pageSize) + pageSize
    const newDisplayReportsPage = this.state.bankRegulatoryReports.data.slice(pageStart, pageEnd)
    const displayReports = this.state.bankRegulatoryReports.displayReports.concat(newDisplayReportsPage)
    this.setState({
      bankRegulatoryReports: {
        ...this.state.bankRegulatoryReports,
        currentPage: page,
        displayReports
      }
    })
  }

  loadOTCInsiderDisclosureSummary = () => {
    getOTCInsiderDisclosureSummary({ symbol: this.props.company }).then(OTCInsiderDisclosureSummary => {
      this.setState({ OTCInsiderDisclosureSummary })
    })
  }

  onSelectTab = (tab) => {
    this.state.financialReports.loaded = false
    this.setState({
      ...this.state,
      selectedTab: tab,
      financialReports: this.state.financialReports
    }, () => {
      this.loadFinancialReports()
    })
  }

  sortBankRegulatoryReports = column => {
    if (this.state.bankRegulatoryReports.displayReports) {
      let sortDirection = 'DESC'
      if (this.state.bankRegulatoryReports.sortDir === 'DESC') sortDirection = 'ASC'

      this.setState({
        bankRegulatoryReports: {
          ...this.state.bankRegulatoryReports,
          sortDir: sortDirection,
          displayReports: this.state.bankRegulatoryReports.displayReports.sort(dynamicSort(column, 'date', sortDirection))
        }
      })
    }
  }

  checkSedarEligibleSecurity = () => {
    const { info } = this.props
    const security = info.currentSecurity
    const isForeignExchangeCountryCanada = security.foreignExchangeCountry === 'CAN'
    return (info.profileNo || isForeignExchangeCountryCanada)
  }

  render () {
    const { company, accent, info } = this.props
    const isSedarEligibleSecurity = this.checkSedarEligibleSecurity();
    const isSedarDataEligibleSecurity = isSedarEligibleSecurity && info.isDnsClient
    const isSedarDataIneligibleSecurity = isSedarEligibleSecurity && !info.isDnsClient
    const fdicReportsExists = this.state.fdicReports.loaded && this.state.fdicReports.data.notEmpty

    return <div className={classNames(styles.CompanyQuoteDisclosure, styles[accent])}>
      <Outline mode='heading' accent={accent}>Filings and Disclosure</Outline>
      <div className={styles.otcDisclosureTable}>
        <Outline mode='section3' accent={accent} margin='none' spacing='large' underline>
          <b>OTC Disclosure & News Service</b>
        </Outline>
        <TableTabs
          accent={accent}
          tabs={[{
            key: 'active',
            title: 'Active',
            func: () => {
              this.onSelectTab('active')
            }
          },
          {
            key: 'inactive',
            title: 'Inactive',
            func: () => {
              this.onSelectTab('inactive')
            }
          },
          {
            key: 'all',
            title: 'All',
            func: () => {
              this.onSelectTab('all')
            }
          }]}
          selectedTab={this.state.selectedTab} />
        <Loading
          type='table'
          emptyMessage={'The company has not provided financial reports or other disclosures to OTC Markets Group.'}
          error={this.state.financialReports.error}
          data={this.state.financialReports.data.records || []}
          loaded={this.state.financialReports.loaded}
          reloading={this.state.financialReports.reloading}>
          <Table
            columns={newsAndDisclosureColumns}
            data={this.state.financialReports.data.records}
            sortColumn={this.state.financialReports.sortOn}
            sortDirection={this.state.financialReports.sortDir}
            onSorting={sort(this, getFinancialReports, 'financialReports')}
            showHeader
            accent={accent}
          />
          <TableFooter>
            <More onClick={expand(this, getFinancialReports, 'financialReports')}
              disabled={this.state.financialReports.expandEmpty} />
            {this.state.financialReports.data.records && this.state.financialReports.data.totalRecords &&
              <DisplayResults show={this.state.financialReports.data.records.length} total={this.state.financialReports.data.totalRecords} text={'Disclosure & News'} />
            }
          </TableFooter>
        </Loading>
      </div>

      {fdicReportsExists && <div id='fdicReports'>
        <Outline mode='invisible' spacing='large' />
        <Outline mode='section3' accent={accent} margin='none' spacing='large'>
          <b>SEC Filings - FDIC</b>
        </Outline>
        <div>
          <Loading
            type='table'
            emptyMessage={`No SEC Filings - FDIC Reports for ${company}`}
            error={this.state.fdicReports.error}
            data={this.state.fdicReports.data.records}
            loaded={this.state.fdicReports.loaded}
            reloading={this.state.fdicReports.reloading}>
            <Table
              columns={fdicReportsColumns(company)}
              data={this.state.fdicReports.data.records}
              showHeader
              accent={accent}
            />
            <TableFooter>
              <More onClick={expand(this, getFDICReports, 'fdicReports')}
                disabled={this.state.fdicReports.expandEmpty} />
              {this.state.fdicReports.data.records && this.state.fdicReports.data.totalRecords &&
                <DisplayResults show={this.state.fdicReports.data.records.length} total={this.state.fdicReports.data.totalRecords} text={'Filings'} />
              }
            </TableFooter>
          </Loading>
        </div>
      </div>
      }
      <Outline mode='invisible' spacing='large' />

      {info.isBankThrift && <div>
        <Outline mode='section3' accent={accent} margin='none' spacing='large'>
          <b>Bank Regulatory Disclosure</b>
        </Outline>
        <div>
          <Loading
            type='table'
            emptyMessage={`No Bank Regulatory Disclosure Reports for ${company}`}
            error={this.state.bankRegulatoryReports.error}
            data={this.state.bankRegulatoryReports.data}
            loaded={this.state.bankRegulatoryReports.loaded}
            reloading={this.state.bankRegulatoryReports.reloading}>
            <Table
              columns={bankRegulatoryReportsColumns(company)}
              data={this.state.bankRegulatoryReports.displayReports}
              sortColumn={this.state.bankRegulatoryReports.sortOn}
              sortDirection={this.state.bankRegulatoryReports.sortDir}
              onSorting={this.sortBankRegulatoryReports}
              showHeader
              accent={accent}
            />
            <TableFooter>
              <More onClick={this.showMoreBankRegulatoryReports}
                disabled={this.state.bankRegulatoryReports.displayReports.length === this.state.bankRegulatoryReports.data.length} />
              {this.state.bankRegulatoryReports.data && this.state.bankRegulatoryReports.data.length > 0 &&
              <DisplayResults show={this.state.bankRegulatoryReports.displayReports.length} total={this.state.bankRegulatoryReports.data.length} text={'Reports'} />
              }
              <div className={styles.footerNote}>Call Reports represent operating bank disclosure. Y-6 and Y-9 Reports represent holding company disclosure.</div>
            </TableFooter>
          </Loading>
        </div>

        <Outline mode='invisible' spacing='large' />
      </div>}

      {isSedarDataIneligibleSecurity && <div><Outline mode='section3' accent={accent} margin='none' spacing='large'>
        <b>SEDAR+<sup>™</sup> Filings</b>
      </Outline>
        <Loading
          type='table'
          data={[]}
          emptyMessage={`SEDAR filings are only displayed for OTCQX, OTCQB and OTCID companies.`}
          loaded>
        </Loading>
        <SedarFooter />
        <Outline mode='invisible' spacing='large' />
      </div>}

      {isSedarDataEligibleSecurity && <div><Outline mode='section3' accent={accent} margin='none' spacing='large'>
        <b>SEDAR+<sup>™</sup> Filings</b>
      </Outline>

        <div>
          <Loading
            type='table'
            emptyMessage={`No SEDAR Filings data available for ${company}.`}
            error={this.state.sedarFilings.error && 'No data available.'}
            data={this.state.sedarFilings.data.records}
            loaded={this.state.sedarFilings.loaded}
            reloading={this.state.sedarFilings.reloading}>
            <Table
              columns={sedarFilingsColumns}
              data={this.state.sedarFilings.data.records}
              sortColumn={this.state.sedarFilings.sortOn}
              sortDirection={this.state.sedarFilings.sortDir}
              showHeader
              accent={accent}
            />
            <TableFooter>
              <More onClick={expand(this, getSedarFilings, 'sedarFilings')}
                disabled={this.state.sedarFilings.expandEmpty} />
              {this.state.sedarFilings.data.records && this.state.sedarFilings.data.totalRecords &&
                <DisplayResults show={this.state.sedarFilings.data.records.length} total={this.state.sedarFilings.data.totalRecords} text={'SEDAR+ Filings'} />
              }
              <SedarFooter />
            </TableFooter>
          </Loading>
        </div>

        <Outline mode='invisible' spacing='large' />
      </div>}

      <Outline mode='section3' accent={accent} margin='none' spacing='large'>
        <b>SEC Filings</b>
      </Outline>
      <div>
        <Loading
          type='table'
          emptyMessage={`No SEC Filings data available for ${company}.`}
          error={this.state.secFilings.error}
          data={this.state.secFilings.data.records}
          loaded={this.state.secFilings.loaded}
          reloading={this.state.secFilings.reloading}>
          <Table
            columns={secFilingsColumns}
            data={this.state.secFilings.data.records}
            sortColumn={this.state.secFilings.sortOn}
            sortDirection={this.state.secFilings.sortDir}
            onSorting={sort(this, getSECFilings, 'secFilings')}
            showHeader
            accent={accent}
          />
          <TableFooter>
            <More onClick={expand(this, getSECFilings, 'secFilings')}
              disabled={this.state.secFilings.expandEmpty} />
            {this.state.secFilings.data.records && this.state.secFilings.data.totalRecords &&
              <DisplayResults show={this.state.secFilings.data.records.length} total={this.state.secFilings.data.totalRecords} text={'Filings'} />
            }
          </TableFooter>
        </Loading>
      </div>

      <Outline mode='invisible' spacing='large' />

      <Outline mode='heading' accent={accent}>Insider Disclosure</Outline>
      <Outline mode='section3' margin='none' spacing='large' underline>
        <b>OTC Disclosure & News</b>
      </Outline>
      <Loading
        type='table'
        emptyMessage={`No insider disclosure available for ${company}.`}
        error={this.state.OTCInsiderDisclosure.error}
        data={this.state.OTCInsiderDisclosure.data}
        loaded={this.state.OTCInsiderDisclosure.loaded}
        reloading={this.state.OTCInsiderDisclosure.reloading}>
        <Table
          columns={OTCInsiderColumns}
          data={this.state.OTCInsiderDisclosure.data.records}
          showHeader />
        <TableFooter>
          <More onClick={expand(this, getOTCInsiderDisclosure, 'OTCInsiderDisclosure')}
            disabled={this.state.OTCInsiderDisclosure.expandEmpty} />
          {this.state.OTCInsiderDisclosure.data.records && this.state.OTCInsiderDisclosure.data.totalRecords &&
            <DisplayResults show={this.state.OTCInsiderDisclosure.data.records.length} total={this.state.OTCInsiderDisclosure.totalRecords} text={'Disclosure & News'} />
          }
        </TableFooter>
      </Loading>

      <Outline mode='invisible' spacing='large' />

      <Outline mode='section3' accent={accent} margin='none' spacing='large'>
        <b>SEC Transactions Last 6 Months</b>
      </Outline>
      <Loading
        height='6em'
        emptyMessage={`No SEC transactions available for ${company}.`}
        loaded={Object.keys(this.state.OTCInsiderDisclosureSummary).length > 0}>
        <Flex wrap>
          <Box w={[1, 0.45]} className={styles.left}>
            <div className={styles.transactionsHeader}>Buy / Sell</div>
            <Flex className={styles.transactionBody}>
              <Box w={[1 / 3]} className={styles.transactionItem}>
                <div className={styles.transactionItemHeader}>Buys</div>
                <Locale type='int'>{this.state.OTCInsiderDisclosureSummary.numberOfBuys}</Locale>
              </Box>
              <Box w={[1 / 3]} className={styles.transactionItem}>
                <div className={styles.transactionItemHeader}>Sells</div>
                <Locale type='int'>{this.state.OTCInsiderDisclosureSummary.numberOfSells}</Locale>
              </Box>
              <Box w={[1 / 3]} className={styles.transactionItem}>
                <div className={styles.transactionItemHeader}>Total</div>
                <Locale type='int'>{this.state.OTCInsiderDisclosureSummary.numberOfTransactions}</Locale>
              </Box>
            </Flex>
          </Box>
          <Box w={[1, 0.55]} className={styles.right}>
            <div className={styles.transactionsHeader}>Shares</div>
            <Flex className={styles.transactionBody}>
              <Box w={[1 / 4]} className={styles.transactionItem}>
                <div className={styles.transactionItemHeader}>Bought</div>
                <Locale type='int'>{this.state.OTCInsiderDisclosureSummary.sharesBought}</Locale>
              </Box>
              <Box w={[1 / 4]} className={styles.transactionItem}>
                <div className={styles.transactionItemHeader}>Sold</div>
                <Locale type='int'>{this.state.OTCInsiderDisclosureSummary.sharesSold}</Locale>
              </Box>
              <Box w={[1 / 4]} className={styles.transactionItem}>
                <div className={styles.transactionItemHeader}>Gross</div>
                <Locale type='int'>{this.state.OTCInsiderDisclosureSummary.grossShares}</Locale>
              </Box>
              <Box w={[1 / 4]} className={styles.transactionItem}>
                <div className={styles.transactionItemHeader}>Net</div>
                <Locale type='int'>{this.state.OTCInsiderDisclosureSummary.netShares}</Locale>
              </Box>
            </Flex>
          </Box>
        </Flex>
      </Loading>

      <Outline mode='invisible' spacing='large' />

      <Outline mode='section3' accent={accent} margin='none' spacing='large'>
        <b>SEC Transactions Last 2 Years</b>
      </Outline>
      <Loading
        type='table'
        emptyMessage={`No SEC transactions available for ${company}.`}
        error={this.state.externalInsiderDisclosure.error}
        data={this.state.externalInsiderDisclosure.data.records}
        loaded={this.state.externalInsiderDisclosure.loaded}
        reloading={this.state.externalInsiderDisclosure.reloading}>
        <Table
          columns={secTransactionsColumns}
          data={this.state.externalInsiderDisclosure.data.records}
          showHeader
          accent={accent}
        />
        <More onClick={expand(this, getExternalInsiderDisclosure, 'externalInsiderDisclosure')}
          disabled={this.state.externalInsiderDisclosure.expandEmpty} />
      </Loading>
    </div>
  }
}

CompanyQuoteDisclosure.propTypes = {
  info: PropTypes.object,
  company: PropTypes.string.isRequired,
  accent: PropTypes.string
}

export default CompanyQuoteDisclosure
